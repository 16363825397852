<template>
  <div class="wizard-body small-9 columns">
    <div v-if="!hasLoginStarted" class="login-init full-height">
      <a href="#" @click="startLogin()">
        <img
          src="~dashboard/assets/images/channels/zalo.png"
          alt="Facebook-logo"
          style="max-height: 48px; max-width: 48px; "
        />
      </a>
    </div>
    <div v-else>
      <form class="row" @submit.prevent="createChannel()">
        <div class="medium-12 columns">
          <page-header
            :header-title="$t('INBOX_MGMT.ADD.ZALO_CHANNEL.TITLE')"
            :header-content="$t('INBOX_MGMT.ADD.ZALO_CHANNEL.DESC')"
          />
        </div>

        <div class="medium-8 columns">
          <label :class="{ error: $v.channelName.$error }">
            {{ $t('INBOX_MGMT.ADD.ZALO_CHANNEL.CHANNEL_NAME.LABEL') }}
            <input
              v-model.trim="channelName"
              type="text"
              :placeholder="
                $t('INBOX_MGMT.ADD.ZALO_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
              "
              @blur="$v.channelName.$touch"
            />
            <span v-if="$v.channelName.$error" class="message">{{
              $t('INBOX_MGMT.ADD.ZALO_CHANNEL.CHANNEL_NAME.ERROR')
            }}</span>
          </label>
        </div>

        <div class="medium-12 columns">
          <woot-submit-button
            :loading="uiFlags.isCreating"
            :button-text="$t('INBOX_MGMT.ADD.ZALO_CHANNEL.SUBMIT_BUTTON')"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
const pkceChallenge = require('pkce-challenge')

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      oaId: '',
      code: '',
      channelName: '',
      code_verifier: '',
      google: {},
      request: {},
      isCreating: false,
      omniauth_token: '',
      user_access_token: '',
      channel: 'facebook',
      hasLoginStarted: false,
    };
  },
  computed: {
    showLoader() {
      return !this.user_access_token || this.isCreating;
    },
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    oaId: { required },
    channelName: { required },
  },
  methods: {
    startLogin() {
      this.code_verifier = pkceChallenge();
      let urlRedirect =
        window.location.protocol+
        '//' +
        window.location.hostname ;
      const win = window.open(
        'https://oauth.zaloapp.com/v4/oa/permission?app_id=' +
          window.chatwootConfig.zaloAppId +
          '&redirect_uri=' +
          encodeURIComponent(urlRedirect) +
          '&code_challenge=' +
          this.code_verifier.code_challenge,
        'ChatBizZalo',
        'width=600, height=480'
      );

      const pollTimer = window.setInterval(async () => {
        try {
          let url = win.document.URL;
          let token = url.startsWith(urlRedirect);
          if (token) {
            let dataCallback = new URL(win.document.URL);
            this.oaId = dataCallback.searchParams.get('oa_id');
            this.code = dataCallback.searchParams.get('code');
            clearInterval(pollTimer);
            win.close();
            this.hasLoginStarted = true;
          }
        } catch (e) {
          console.log(e.message);
        }
      }, 1000);
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const ZaloChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.channelName,
            channel: {
              type: 'zalo',
              oa_id: this.oaId,
              auth_code: this.code,
              code_verifier: this.code_verifier.code_verifier,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: ZaloChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.ZALO_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
